import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
  useIonAlert,
} from "@ionic/react";
import { nanoid } from "nanoid";
import { closeOutline, addOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useActions } from "../../providers/actions-provider";

export const initialValue = () => ({
  id: nanoid(),
  title: "Crypto price",
  cryptos: [
    { id: nanoid(), symbol: "BTC" },
    { id: nanoid(), symbol: "ETH" },
    { id: nanoid(), symbol: "USDT" },
    { id: nanoid(), symbol: "USDC" },
    { id: nanoid(), symbol: "DOGE" },
  ],
});

export const mapToUI = (action: any) => ({
  id: action.id,
  title: action.label,
  cryptos:
    action?.params?.items?.map((crypto: any) => ({
      id: crypto.id,
      symbol: crypto.label,
    })) ?? [],
});

export const mapToIQ = (action: any) => ({
  id: action.id,
  type: "crypto",
  label: action.title,
  params: {
    id: "symbol",
    label: "Symbol",
    items: action.cryptos.map((crypto: any) => ({
      id: crypto.id,
      label: crypto.symbol,
    })),
  },
});

const CryptoAction: React.FC = () => {
  const { actionId } = useParams<any>();
  const { actions, createAction, updateAction, deleteAction } = useActions();
  const history = useHistory();
  const [openAlert] = useIonAlert();
  const [action, setAction] = useState<any>({});

  const isNew = !actionId;

  const handleSave = () => {
    const { title, cryptos } = action;
    if (!title || !cryptos.length) {
      return openAlert({
        header: "Error",
        message: "Title and at least one symbol is required",
        buttons: ["OK"],
      });
    }
    onDismiss();
    if (isNew) {
      createAction(mapToIQ(action));
    } else {
      updateAction(mapToIQ(action));
    }
  };

  const handleDelete = () => {
    onDismiss();
    deleteAction({ id: actionId });
  };

  const handleReorderStocks = (event: CustomEvent<ItemReorderEventDetail>) => {
    const { from, to } = event.detail;
    const cryptos = action.cryptos;
    const copyOfTo = cryptos[to];
    cryptos[to] = cryptos[from];
    cryptos[from] = copyOfTo;
    setAction({
      ...action,
      cryptos,
    });
    event.detail.complete();
  };

  const handleOpenStock = (crypto?: any) => {
    const isAddingStock = !crypto;
    openAlert({
      header: isAddingStock ? "Adding crypto" : "Editing crypto",
      inputs: [
        {
          name: "symbol",
          placeholder: "Symbol",
          value: crypto?.symbol,
          max: 5,
        },
      ],
      buttons: isAddingStock
        ? [
            {
              text: "Add Crypto",
              handler: (input) => {
                const { symbol } = input;
                if (symbol) {
                  setAction({
                    ...action,
                    cryptos: [...action.cryptos, { id: nanoid(), symbol }],
                  });
                }
              },
            },
          ]
        : [
            {
              text: "Delete",
              cssClass: "!text-red-400",
              handler: () => {
                setAction({
                  ...action,
                  cryptos: action.cryptos.filter(
                    (element: any) => crypto.id !== element.id
                  ),
                });
              },
            },
            {
              text: "Update",
              handler: (input) => {
                const { symbol } = input;
                if (symbol) {
                  setAction({
                    ...action,
                    cryptos: action.cryptos.map((element: any) => {
                      if (crypto.id === element.id) {
                        return {
                          ...element,
                          symbol,
                        };
                      }
                      return element;
                    }),
                  });
                }
              },
            },
          ],
    });
  };

  const onDismiss = () => history.replace("/dashboard");

  useEffect(() => {
    if (isNew) {
      setAction(initialValue());
    } else {
      const current = actions?.find((action) => action.id === actionId);
      if (current) {
        setAction(mapToUI(current));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonModal isOpen onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar style={{ "--background": "transparent" }}>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/dashboard">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="my-2 mx-4 flex flex-col gap-4">
          <IonInput
            counter
            maxlength={32}
            label="Title"
            labelPlacement="floating"
            fill="outline"
            value={action.title}
            onIonInput={(ev) =>
              setAction({ ...action, title: ev.target.value })
            }
          />
          <IonLabel>Available Cryptos</IonLabel>
          <IonList>
            <IonReorderGroup
              disabled={false}
              onIonItemReorder={handleReorderStocks}
            >
              {action?.cryptos?.map((crypto: any) => (
                <IonItem
                  key={crypto.id}
                  button
                  onClick={() => handleOpenStock(crypto)}
                >
                  <IonLabel>{crypto.symbol}</IonLabel>
                  <IonReorder slot="end"></IonReorder>
                </IonItem>
              ))}
            </IonReorderGroup>
            <IonItem button onClick={() => handleOpenStock()}>
              <IonIcon icon={addOutline} slot="end"></IonIcon>
              <IonLabel className="font-bold">Add Symbol</IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <>
            <IonButtons slot="start">
              {!isNew && (
                <IonButton color="danger" onClick={handleDelete}>
                  Delete
                </IonButton>
              )}
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSave}>
                {isNew ? "Create" : "Update"} Action
              </IonButton>
            </IonButtons>
          </>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default CryptoAction;
