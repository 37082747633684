import { IonHeader, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router";

const Header: React.FC = () => {
  const history = useHistory();
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          <div
            className="flex gap-2 justify-center cursor-pointer"
            onClick={() => history.replace("/")}
          >
            <img
              className="w-6 h-6"
              style={{ transition: "all ease 0.3s" }}
              src="/assets/icon/icon-outline.png"
              alt="logo"
            />
            <IonText>Garminfy</IonText>
          </div>
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
