import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
  useIonAlert,
} from "@ionic/react";
import { nanoid } from "nanoid";
import { closeOutline, addOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useActions } from "../../providers/actions-provider";

export const initialValue = () => ({
  id: nanoid(),
  title: "Currency exchange",
  currencies: [
    { id: nanoid(), from: "EUR", to: "USD" },
    { id: nanoid(), from: "GBP", to: "USD" },
    { id: nanoid(), from: "JPY", to: "USD" },
    { id: nanoid(), from: "CAD", to: "USD" },
    { id: nanoid(), from: "CNY", to: "USD" },
  ],
});

export const mapToUI = (action: any) => ({
  id: action.id,
  title: action.label,
  currencies:
    action?.params?.items?.map((currency: any) => ({
      id: currency.id,
      from: currency.pair?.split("_")[0],
      to: currency.pair?.split("_")[1],
    })) ?? [],
});

export const mapToIQ = (action: any) => ({
  id: action.id,
  type: "currency",
  label: action.title,
  params: {
    id: "pair",
    label: "Currency Pair",
    items: action.currencies.map((currency: any) => ({
      id: currency.id,
      label: currency.from + " to " + currency.to,
      pair: currency.from + "_" + currency.to,
    })),
  },
});

const CurrencyAction: React.FC = () => {
  const { actionId } = useParams<any>();
  const { actions, createAction, updateAction, deleteAction } = useActions();
  const history = useHistory();
  const [openAlert] = useIonAlert();
  const [action, setAction] = useState<any>({});

  const isNew = !actionId;

  const handleSave = () => {
    const { title, currencies } = action;
    if (!title || !currencies.length) {
      return openAlert({
        header: "Error",
        message: "Title and at least one pair of currencies is required",
        buttons: ["OK"],
      });
    }
    onDismiss();
    if (isNew) {
      createAction(mapToIQ(action));
    } else {
      updateAction(mapToIQ(action));
    }
  };

  const handleDelete = () => {
    onDismiss();
    deleteAction({ id: actionId });
  };

  const handleReorderCurrencies = (
    event: CustomEvent<ItemReorderEventDetail>
  ) => {
    const { from, to } = event.detail;
    const currencies = action.currencies;
    const copyOfTo = currencies[to];
    currencies[to] = currencies[from];
    currencies[from] = copyOfTo;
    setAction({
      ...action,
      currencies,
    });
    event.detail.complete();
  };

  const handleOpenCurrencyPair = (currency?: any) => {
    const isAddingCurrency = !currency;
    openAlert({
      header: isAddingCurrency
        ? "Adding currency pair"
        : "Editing currency pair",
      inputs: [
        {
          name: "from",
          placeholder: "From",
          value: currency?.from,
          max: 5,
        },
        {
          name: "to",
          placeholder: "To",
          value: currency?.to,
          max: 5,
        },
      ],
      buttons: isAddingCurrency
        ? [
            {
              text: "Add Currency pair",
              handler: (input) => {
                const { from, to } = input;
                if (from && to) {
                  setAction({
                    ...action,
                    currencies: [
                      ...action.currencies,
                      { id: nanoid(), from, to },
                    ],
                  });
                }
              },
            },
          ]
        : [
            {
              text: "Delete",
              cssClass: "!text-red-400",
              handler: () => {
                setAction({
                  ...action,
                  currencies: action.currencies.filter(
                    (element: any) => currency.id !== element.id
                  ),
                });
              },
            },
            {
              text: "Update",
              handler: (input) => {
                const { from, to } = input;
                if (from && to) {
                  setAction({
                    ...action,
                    currencies: action.currencies.map((element: any) => {
                      if (currency.id === element.id) {
                        return {
                          ...element,
                          from,
                          to,
                        };
                      }
                      return element;
                    }),
                  });
                }
              },
            },
          ],
    });
  };

  const onDismiss = () => history.replace("/dashboard");

  useEffect(() => {
    if (isNew) {
      setAction(initialValue());
    } else {
      const current = actions?.find((action) => action.id === actionId);
      if (current) {
        setAction(mapToUI(current));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonModal isOpen onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar style={{ "--background": "transparent" }}>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/dashboard">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="my-2 mx-4 flex flex-col gap-4">
          <IonInput
            counter
            maxlength={32}
            label="Title"
            labelPlacement="floating"
            fill="outline"
            value={action.title}
            onIonInput={(ev) =>
              setAction({ ...action, title: ev.target.value })
            }
          />
          <IonLabel>Available Currency Pairs</IonLabel>
          <IonList>
            <IonReorderGroup
              disabled={false}
              onIonItemReorder={handleReorderCurrencies}
            >
              {action?.currencies?.map((currency: any) => (
                <IonItem
                  key={currency.id}
                  button
                  onClick={() => handleOpenCurrencyPair(currency)}
                >
                  <IonLabel>
                    {currency.from} to {currency.to}
                  </IonLabel>
                  <IonReorder slot="end"></IonReorder>
                </IonItem>
              ))}
            </IonReorderGroup>
            <IonItem button onClick={() => handleOpenCurrencyPair()}>
              <IonIcon icon={addOutline} slot="end"></IonIcon>
              <IonLabel className="font-bold">Add Currency Pair</IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <>
            <IonButtons slot="start">
              {!isNew && (
                <IonButton color="danger" onClick={handleDelete}>
                  Delete
                </IonButton>
              )}
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSave}>
                {isNew ? "Create" : "Update"} Action
              </IonButton>
            </IonButtons>
          </>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default CurrencyAction;
