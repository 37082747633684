import { createContext, ReactNode, useContext } from "react";
import { useFirebase } from "./firebase-provider";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc, updateDoc } from "firebase/firestore";

interface IActions {
  loading: boolean;
  actions: any[];
  createAction: (action: any) => void;
  updateAction: (action: any) => void;
  deleteAction: (action: any) => void;
  reorderAction: (from: number, to: number) => void;
}

const ActionsContext = createContext<IActions>({} as IActions);

export const useActions = () => useContext(ActionsContext);

export const ActionsProvider = ({ children }: { children: ReactNode }) => {
  const { auth, firestore } = useFirebase();
  const userId = auth.currentUser?.uid;
  const [userDoc, loading] = useDocument(doc(firestore, `users/${userId}`));
  const actions: [any] = userDoc?.data()?.actions ?? [];

  const createAction = async (action: any) => {
    actions.push(action);
    return updateDoc(doc(firestore, `users/${userId}`), {
      actions,
    });
  };

  const updateAction = (action: any) => {
    const elementIndex = actions.findIndex(
      (element) => element.id === action.id
    );
    actions[elementIndex] = action;
    return updateDoc(doc(firestore, `users/${userId}`), {
      actions,
    });
  };

  const deleteAction = (action: any) => {
    return updateDoc(doc(firestore, `users/${userId}`), {
      actions: actions.filter((element: any) => element.id !== action.id),
    });
  };

  const reorderAction = (from: number, to: number) => {
    const copyOfTo = actions[to];
    actions[to] = actions[from];
    actions[from] = copyOfTo;
    updateDoc(doc(firestore, `users/${userId}`), { actions });
  };

  const value = {
    loading,
    actions,
    createAction,
    updateAction,
    deleteAction,
    reorderAction,
  };
  return (
    <ActionsContext.Provider value={value}>{children}</ActionsContext.Provider>
  );
};
