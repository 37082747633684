import { IonContent, IonLabel, IonPage, useIonLoading } from "@ionic/react";
import SignInCard from "../components/SignInCard";
import useQueryParams from "../hooks/use-query-params";
import { User } from "firebase/auth";
import Header from "../components/Header";
import { useState } from "react";
import SignUpCard from "../components/SignUpCard";

const Authorize: React.FC = () => {
  const [authForm, setAuthForm] = useState("signin");
  const [showLoading] = useIonLoading();
  const queryParams = useQueryParams();
  const redirectUri = queryParams.get("redirect_uri");

  const handleAuthorize = async (user: User) => {
    showLoading({
      message: "Redirecting...",
    });
    const token = await user.getIdToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(
      `${process.env.REACT_APP_FUNCTIONS_API}/oauth/authorize`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        window.location.assign(`${redirectUri}?code=${data.code}`);
      });
  };

  return (
    <IonPage>
      <Header />
      <IonContent>
        <div className="flex flex-col items-center justify-center h-screen">
          {!redirectUri ? (
            <IonLabel>Invalid Authorization</IonLabel>
          ) : (
            <>
              {authForm === "signin" && (
                <SignInCard
                  title="Connect your Account"
                  hideResetPassword
                  onSignIn={(credentials) => {
                    handleAuthorize(credentials.user);
                  }}
                  onChangeForm={setAuthForm}
                />
              )}
              {authForm === "signup" && (
                <SignUpCard
                  onSignUp={(credentials) => {
                    handleAuthorize(credentials.user);
                  }}
                  onChangeForm={setAuthForm}
                />
              )}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Authorize;
