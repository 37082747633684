import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
  useIonAlert,
} from "@ionic/react";
import { nanoid } from "nanoid";
import { closeOutline, addOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useActions } from "../../providers/actions-provider";

export const initialValue = () => ({
  id: nanoid(),
  title: "Text message",
  contacts: [],
  messages: [
    { id: nanoid(), text: "On my way!" },
    { id: nanoid(), text: "I will call you later" },
    { id: nanoid(), text: "Sorry, I can't talk now" },
  ],
});

export const mapToUI = (action: any) => ({
  id: action.id,
  title: action.label,
  contacts:
    action?.params?.items?.map((contact: any) => ({
      id: contact.id,
      name: contact.label,
      phone: contact.value,
    })) ?? [],
  messages:
    action?.params?.params?.items?.map((message: any) => ({
      id: message.id,
      text: message.label,
    })) ?? [],
});

export const mapToIQ = (action: any) => ({
  id: action.id,
  type: "sms",
  label: action.title,
  params: {
    id: "contact",
    label: "Contacts",
    items: action.contacts.map((contact: any) => ({
      id: contact.id,
      label: contact.name,
      value: contact.phone,
    })),
    params: {
      id: "message",
      label: "Messages",
      items: action.messages.map((message: any) => ({
        id: message.id,
        label: message.text,
      })),
    },
  },
});

const TextMessageAction: React.FC = () => {
  const { actionId } = useParams<any>();
  const { actions, createAction, updateAction, deleteAction } = useActions();
  const history = useHistory();
  const [openAlert] = useIonAlert();
  const [action, setAction] = useState<any>({});

  const isNew = !actionId;

  const handleSave = () => {
    const { title, contacts, messages } = action;
    if (!title || !contacts.length || !messages.length) {
      return openAlert({
        header: "Error",
        message: "Title and at least one contact and message are required",
        buttons: ["OK"],
      });
    }
    onDismiss();
    if (isNew) {
      createAction(mapToIQ(action));
    } else {
      updateAction(mapToIQ(action));
    }
  };

  const handleDelete = () => {
    onDismiss();
    deleteAction({ id: actionId });
  };

  const handleReorderContacts = (
    event: CustomEvent<ItemReorderEventDetail>
  ) => {
    const { from, to } = event.detail;
    const contacts = action.contacts;
    const copyOfTo = contacts[to];
    contacts[to] = contacts[from];
    contacts[from] = copyOfTo;
    setAction({
      ...action,
      contacts,
    });
    event.detail.complete();
  };

  const handleOpenContact = (contact?: any) => {
    const isAddingContact = !contact;
    openAlert({
      header: isAddingContact ? "Adding contact" : "Editing contact",
      inputs: [
        { name: "name", placeholder: "Name", value: contact?.name },
        {
          name: "phone",
          placeholder: "Phone",
          value: contact?.phone,
          type: "tel",
        },
      ],
      buttons: isAddingContact
        ? [
            {
              text: "Create Contact",
              cssClass: "!text-white-400",
              handler: (input) => {
                const { name, phone } = input;
                if (name && phone) {
                  setAction({
                    ...action,
                    contacts: [
                      ...action.contacts,
                      { id: nanoid(), name, phone },
                    ],
                  });
                }
              },
            },
          ]
        : [
            {
              text: "Delete",
              cssClass: "!text-red-400",
              handler: () => {
                setAction({
                  ...action,
                  contacts: action.contacts.filter(
                    (_contact: any) => contact.id !== _contact.id
                  ),
                });
              },
            },
            {
              text: "Update",
              handler: (input) => {
                const { name, phone } = input;
                if (name && phone) {
                  setAction({
                    ...action,
                    contacts: action.contacts.map((_contact: any) => {
                      if (contact.id === _contact.id) {
                        return {
                          ..._contact,
                          name,
                          phone,
                        };
                      }
                      return _contact;
                    }),
                  });
                }
              },
            },
          ],
    });
  };

  const handleReorderMessages = (
    event: CustomEvent<ItemReorderEventDetail>
  ) => {
    const { from, to } = event.detail;
    const messages = action.messages;
    const copyOfTo = messages[to];
    messages[to] = messages[from];
    messages[from] = copyOfTo;
    setAction({
      ...action,
      messages,
    });
    event.detail.complete();
  };

  const handleOpenMessage = (message?: any) => {
    const isAddingMessage = !message;
    openAlert({
      header: isAddingMessage ? "Adding message" : "Editing message",
      inputs: [
        { name: "text", placeholder: "Message", value: message?.text, max: 32 },
      ],
      buttons: isAddingMessage
        ? [
            {
              text: "Create Message",
              handler: (input) => {
                const { text } = input;
                if (text) {
                  setAction({
                    ...action,
                    messages: [...action.messages, { id: nanoid(), text }],
                  });
                }
              },
            },
          ]
        : [
            {
              text: "Delete",
              cssClass: "!text-red-400",
              handler: () => {
                setAction({
                  ...action,
                  messages: action.messages.filter(
                    (_message: any) => message.id !== _message.id
                  ),
                });
              },
            },
            {
              text: "Update",
              handler: (input) => {
                const { text } = input;
                if (text) {
                  setAction({
                    ...action,
                    messages: action.messages.map((_message: any) => {
                      if (message.id === _message.id) {
                        return {
                          ..._message,
                          text,
                        };
                      }
                      return _message;
                    }),
                  });
                }
              },
            },
          ],
    });
  };

  const onDismiss = () => history.replace("/dashboard");

  useEffect(() => {
    if (isNew) {
      setAction(initialValue());
    } else {
      const current = actions?.find((action) => action.id === actionId);
      if (current) {
        setAction(mapToUI(current));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonModal isOpen onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar style={{ "--background": "transparent" }}>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/dashboard">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="my-2 mx-4 flex flex-col gap-4">
          <IonInput
            counter
            maxlength={32}
            label="Title"
            labelPlacement="floating"
            fill="outline"
            value={action.title}
            onIonInput={(ev) =>
              setAction({ ...action, title: ev.target.value })
            }
          />
          <IonLabel>Available Contacts</IonLabel>
          <IonList>
            <IonReorderGroup
              disabled={false}
              onIonItemReorder={handleReorderContacts}
            >
              {action?.contacts?.map((contact: any) => (
                <IonItem
                  key={contact.id}
                  button
                  onClick={() => handleOpenContact(contact)}
                >
                  <IonLabel>{contact.name}</IonLabel>
                  <IonReorder slot="end"></IonReorder>
                </IonItem>
              ))}
            </IonReorderGroup>
            <IonItem button onClick={() => handleOpenContact()}>
              <IonIcon icon={addOutline} slot="end"></IonIcon>
              <IonLabel className="font-bold">Add Contact</IonLabel>
            </IonItem>
          </IonList>
          <IonLabel>Available Messages</IonLabel>
          <IonList>
            <IonReorderGroup
              disabled={false}
              onIonItemReorder={handleReorderMessages}
            >
              {action?.messages?.map((message: any) => (
                <IonItem
                  key={message.id}
                  button
                  onClick={() => handleOpenMessage(message)}
                >
                  <IonLabel>{message.text}</IonLabel>
                  <IonReorder slot="end"></IonReorder>
                </IonItem>
              ))}
            </IonReorderGroup>
            <IonItem button onClick={() => handleOpenMessage()}>
              <IonIcon icon={addOutline} slot="end"></IonIcon>
              <IonLabel className="font-bold">Add Message</IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <>
            <IonButtons slot="start">
              {!isNew && (
                <IonButton color="danger" onClick={handleDelete}>
                  Delete
                </IonButton>
              )}
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSave}>
                {isNew ? "Create" : "Update"} Action
              </IonButton>
            </IonButtons>
          </>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default TextMessageAction;
