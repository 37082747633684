import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { signInWithEmailAndPassword, UserCredential } from "firebase/auth";
import { useState } from "react";
import { useFirebase } from "../providers/firebase-provider";

interface ISignInCard {
  title?: string;
  hideResetPassword?: boolean;
  hideSignUp?: boolean;
  onSignIn: (credentials: UserCredential) => void;
  onChangeForm: (form: string) => void;
}

const SignInCard: React.FC<ISignInCard> = ({
  title = "Access to your account",
  hideResetPassword,
  hideSignUp,
  onSignIn,
  onChangeForm,
}) => {
  const { auth } = useFirebase();
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = async () => {
    showLoading({
      message: "Authenticating...",
    });
    try {
      const credentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      onSignIn(credentials);
    } catch (_) {
      showAlert({
        header: "Error",
        message: "Invalid credentials",
        buttons: ["OK"],
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <IonCard className="min-w-[320px] py-4">
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="flex flex-col gap-4">
          <IonInput
            label="Email"
            labelPlacement="floating"
            fill="outline"
            placeholder="Email"
            value={email}
            onIonInput={(ev) => setEmail(ev.target.value as string)}
          />
          <IonInput
            label="Password"
            labelPlacement="floating"
            fill="outline"
            type="password"
            value={password}
            onIonInput={(ev) => setPassword(ev.target.value as string)}
          />
          {!hideResetPassword && (
            <div className="text-right">
              <IonButton
                size="small"
                fill="clear"
                onClick={() => onChangeForm("reset-password")}
                className="text-sm normal-case"
              >
                Forgot password?
              </IonButton>
            </div>
          )}
          <IonButton expand="block" onClick={handleSignIn}>
            Sign In
          </IonButton>
          {!hideSignUp && (
            <div>
              <IonButton
                size="small"
                fill="clear"
                onClick={() => onChangeForm("signup")}
                className="text-sm normal-case"
              >
                Don't have an account yet ? Sign Up
              </IonButton>
            </div>
          )}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default SignInCard;
