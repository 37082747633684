import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useFirebase } from "../providers/firebase-provider";

const ResetPasswordCard: React.FC<{
  onChangeForm: (form: string) => void;
}> = ({ onChangeForm }) => {
  const { auth } = useFirebase();
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();
  const [email, setEmail] = useState("");

  const handleResetPassword = async () => {
    showLoading({
      message: "Checking email...",
    });
    try {
      await sendPasswordResetEmail(auth, email);
      hideLoading();
      showAlert({
        header: "Confirmation",
        message:
          "Check your inbox for instructions on how to reset the password",
        buttons: ["OK"],
      });
      onChangeForm("signin");
    } catch (e: any) {
      hideLoading();
      showAlert({
        header: "Error",
        message:
          "Looks like this emails is not associated with an existing account.",
        buttons: ["OK"],
      });
    }
  };

  return (
    <IonCard className="min-w-[320px] py-4">
      <IonCardHeader>
        <IonCardTitle>Password recovery</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="flex flex-col gap-4">
          <IonInput
            label="Email"
            labelPlacement="floating"
            fill="outline"
            placeholder="Email"
            value={email}
            onIonInput={(ev) => setEmail(ev.target.value as string)}
          />
          <div className="text-right">
            <IonButton
              size="small"
              fill="clear"
              onClick={() => onChangeForm("signin")}
              className="text-sm normal-case"
            >
              I remember my password
            </IonButton>
          </div>
          <IonButton
            className="my-4"
            expand="block"
            onClick={handleResetPassword}
          >
            Recover password
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default ResetPasswordCard;
