import { useEffect } from "react";
import { useHistory } from "react-router";
import useFirebaseAuth from "../hooks/use-firebase-auth";

const REDIRECT_PATH = "/login";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user, loading } = useFirebaseAuth();
  const history = useHistory();
  const isAuthenticated = !!user;
  useEffect(() => {
    if (!loading && !isAuthenticated) {
      history.replace(REDIRECT_PATH);
    }
  }, [loading, isAuthenticated, history]);
  // Loading
  if (loading) {
    return null;
  }
  // Not authenticated
  if (!isAuthenticated) {
    return null;
  }
  return children;
};

export default RequireAuth;
