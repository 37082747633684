import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import {
  UserCredential,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { useState } from "react";
import { useFirebase } from "../providers/firebase-provider";
import { doc, setDoc } from "firebase/firestore";

import * as CryptoAction from "./actions/CryptoAction";
import * as StockAction from "./actions/StockAction";
import * as CurrencyAction from "./actions/CurrencyAction";
import * as WorldTimeAction from "./actions/WorldTimeAction";
import * as QuoteAction from "./actions/QuoteAction";

interface ISignUpCard {
  title?: string;
  onChangeForm: (form: string) => void;
  onSignUp: (credentials: UserCredential) => void;
}

const SignUpCard: React.FC<ISignUpCard> = ({
  title = "Create an account",
  onSignUp,
  onChangeForm,
}) => {
  const { auth, firestore } = useFirebase();
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleSignUp = async () => {
    if (!name) {
      return showAlert({
        header: "Error",
        message: "The name is required",
        buttons: ["OK"],
      });
    }
    if (password !== passwordConfirmation) {
      return showAlert({
        header: "Error",
        message: "Passwords don't match",
        buttons: ["OK"],
      });
    }
    showLoading({
      message: "Creating account...",
    });
    try {
      // Create user
      const credentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Update user name
      await updateProfile(credentials.user, { displayName: name });
      // Add initial data
      await setDoc(doc(firestore, `users/${credentials.user.uid}`), {
        actions: [
          WorldTimeAction.mapToIQ(WorldTimeAction.initialValue()),
          StockAction.mapToIQ(StockAction.initialValue()),
          CryptoAction.mapToIQ(CryptoAction.initialValue()),
          CurrencyAction.mapToIQ(CurrencyAction.initialValue()),
          QuoteAction.mapToIQ(QuoteAction.initialValue()),
        ],
      });
      //
      onSignUp(credentials);
    } catch (e: any) {
      showAlert({
        header: "Error",
        message:
          e?.message?.replace("Firebase: ", "") || "Error creating the account",
        buttons: ["OK"],
      });
    } finally {
      hideLoading();
    }
  };
  return (
    <IonCard className="min-w-[320px] py-4">
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="flex flex-col gap-4">
          <IonInput
            label="Name"
            labelPlacement="floating"
            fill="outline"
            placeholder="Name"
            value={name}
            onIonInput={(ev) => setName(ev.target.value as string)}
          />
          <IonInput
            label="Email"
            labelPlacement="floating"
            fill="outline"
            placeholder="Email"
            value={email}
            onIonInput={(ev) => setEmail(ev.target.value as string)}
          />
          <IonInput
            label="Password"
            labelPlacement="floating"
            fill="outline"
            type="password"
            value={password}
            onIonInput={(ev) => setPassword(ev.target.value as string)}
          />
          <IonInput
            label="Password confirmation"
            labelPlacement="floating"
            fill="outline"
            type="password"
            value={passwordConfirmation}
            onIonInput={(ev) =>
              setPasswordConfirmation(ev.target.value as string)
            }
          />
          <IonButton expand="block" onClick={handleSignUp}>
            Sign Up
          </IonButton>
          <div>
            <IonButton
              size="small"
              fill="clear"
              onClick={() => onChangeForm("signin")}
              className="text-sm normal-case"
            >
              Have an account already? Login
            </IonButton>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default SignUpCard;
