import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { nanoid } from "nanoid";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useActions } from "../../providers/actions-provider";

export const initialValue = () => ({
  id: nanoid(),
  title: "Inspirational quote",
});

export const mapToUI = (action: any) => ({
  id: action.id,
  title: action.label,
});

export const mapToIQ = (action: any) => ({
  id: action.id,
  type: "quote",
  label: action.title,
});

const QuoteAction: React.FC = () => {
  const { actionId } = useParams<any>();
  const { actions, createAction, updateAction, deleteAction } = useActions();
  const history = useHistory();
  const [openAlert] = useIonAlert();
  const [action, setAction] = useState<any>({});

  const isNew = !actionId;

  const handleSave = () => {
    const { title } = action;
    if (!title) {
      return openAlert({
        header: "Error",
        message: "Title is required",
        buttons: ["OK"],
      });
    }
    onDismiss();
    if (isNew) {
      createAction(mapToIQ(action));
    } else {
      updateAction(mapToIQ(action));
    }
  };

  const handleDelete = () => {
    onDismiss();
    deleteAction({ id: actionId });
  };

  const onDismiss = () => history.replace("/dashboard");

  useEffect(() => {
    if (isNew) {
      setAction(initialValue());
    } else {
      const current = actions?.find((action) => action.id === actionId);
      if (current) {
        setAction(mapToUI(current));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonModal isOpen onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar style={{ "--background": "transparent" }}>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/dashboard">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="my-2 mx-4 flex flex-col gap-4">
          <IonInput
            counter
            maxlength={32}
            label="Title"
            labelPlacement="floating"
            fill="outline"
            value={action.title}
            onIonInput={(ev) =>
              setAction({ ...action, title: ev.target.value })
            }
          />
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <>
            <IonButtons slot="start">
              {!isNew && (
                <IonButton color="danger" onClick={handleDelete}>
                  Delete
                </IonButton>
              )}
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSave}>
                {isNew ? "Create" : "Update"} Action
              </IonButton>
            </IonButtons>
          </>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default QuoteAction;
