import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToggle,
  IonToolbar,
  ItemReorderEventDetail,
  useIonAlert,
} from "@ionic/react";
import { nanoid } from "nanoid";
import { closeOutline, addOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useActions } from "../../providers/actions-provider";

export const initialValue = () => ({
  id: nanoid(),
  title: "World time",
  military: false,
  cities: [
    { id: nanoid(), name: "New York" },
    { id: nanoid(), name: "London" },
    { id: nanoid(), name: "Paris" },
    { id: nanoid(), name: "Tokio" },
    { id: nanoid(), name: "Istanbul" },
    { id: nanoid(), name: "Shanghai" },
    { id: nanoid(), name: "Beijing" },
    { id: nanoid(), name: "Sydney" },
    { id: nanoid(), name: "Moscow" },
  ],
});

export const mapToUI = (action: any) => ({
  id: action.id,
  title: action.label,
  military: action.military,
  cities:
    action?.params?.items?.map((city: any) => ({
      id: city.id,
      name: city.label,
    })) ?? [],
});

export const mapToIQ = (action: any) => ({
  id: action.id,
  type: "time",
  label: action.title,
  military: action.military,
  params: {
    id: "city",
    label: "City",
    items: action.cities.map((city: any) => ({
      id: city.id,
      label: city.name,
    })),
  },
});

const WorldTimeAction: React.FC = () => {
  const { actionId } = useParams<any>();
  const { actions, createAction, updateAction, deleteAction } = useActions();
  const history = useHistory();
  const [openAlert] = useIonAlert();
  const [action, setAction] = useState<any>({});

  const isNew = !actionId;

  const handleSave = () => {
    const { title, cities } = action;
    if (!title || !cities.length) {
      return openAlert({
        header: "Error",
        message: "Title and at least one name is required",
        buttons: ["OK"],
      });
    }
    onDismiss();
    if (isNew) {
      createAction(mapToIQ(action));
    } else {
      updateAction(mapToIQ(action));
    }
  };

  const handleDelete = () => {
    onDismiss();
    deleteAction({ id: actionId });
  };

  const handleReorderCities = (event: CustomEvent<ItemReorderEventDetail>) => {
    const { from, to } = event.detail;
    const cities = action.cities;
    const copyOfTo = cities[to];
    cities[to] = cities[from];
    cities[from] = copyOfTo;
    setAction({
      ...action,
      cities,
    });
    event.detail.complete();
  };

  const handleOpenCity = (city?: any) => {
    const isAddingCity = !city;
    openAlert({
      header: isAddingCity ? "Adding city" : "Editing city",
      inputs: [
        {
          name: "name",
          placeholder: "City",
          value: city?.name,
          max: 5,
        },
      ],
      buttons: isAddingCity
        ? [
            {
              text: "Add City",
              handler: (input) => {
                const { name } = input;
                if (name) {
                  setAction({
                    ...action,
                    cities: [...action.cities, { id: nanoid(), name }],
                  });
                }
              },
            },
          ]
        : [
            {
              text: "Delete",
              cssClass: "!text-red-400",
              handler: () => {
                setAction({
                  ...action,
                  cities: action.cities.filter(
                    (element: any) => city.id !== element.id
                  ),
                });
              },
            },
            {
              text: "Update",
              handler: (input) => {
                const { name } = input;
                if (name) {
                  setAction({
                    ...action,
                    cities: action.cities.map((element: any) => {
                      if (city.id === element.id) {
                        return {
                          ...element,
                          name,
                        };
                      }
                      return element;
                    }),
                  });
                }
              },
            },
          ],
    });
  };

  const onDismiss = () => history.replace("/dashboard");

  useEffect(() => {
    if (isNew) {
      initialValue();
    } else {
      const current = actions?.find((action) => action.id === actionId);
      if (current) {
        setAction(mapToUI(current));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonModal isOpen onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar style={{ "--background": "transparent" }}>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/dashboard">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="my-2 mx-4 flex flex-col gap-4">
          <IonInput
            counter
            maxlength={32}
            label="Title"
            labelPlacement="floating"
            fill="outline"
            value={action.title}
            onIonInput={(ev) =>
              setAction({ ...action, title: ev.target.value })
            }
          />
          <IonToggle
            checked={action.military}
            color="secondary"
            onIonChange={(ev) =>
              setAction({ ...action, military: ev.target.checked })
            }
          >
            24 Hour time
          </IonToggle>
          <IonLabel>Available Cities</IonLabel>
          <IonList>
            <IonReorderGroup
              disabled={false}
              onIonItemReorder={handleReorderCities}
            >
              {action?.cities?.map((city: any) => (
                <IonItem
                  key={city.id}
                  button
                  onClick={() => handleOpenCity(city)}
                >
                  <IonLabel>{city.name}</IonLabel>
                  <IonReorder slot="end"></IonReorder>
                </IonItem>
              ))}
            </IonReorderGroup>
            <IonItem button onClick={() => handleOpenCity()}>
              <IonIcon icon={addOutline} slot="end"></IonIcon>
              <IonLabel className="font-bold">Add City</IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <>
            <IonButtons slot="start">
              {!isNew && (
                <IonButton color="danger" onClick={handleDelete}>
                  Delete
                </IonButton>
              )}
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSave}>
                {isNew ? "Create" : "Update"} Action
              </IonButton>
            </IonButtons>
          </>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default WorldTimeAction;
