import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
  useIonAlert,
} from "@ionic/react";
import { nanoid } from "nanoid";
import { closeOutline, addOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useActions } from "../../providers/actions-provider";

export const initialValue = () => ({
  id: nanoid(),
  title: "Stock price",
  stocks: [
    { id: nanoid(), symbol: "TSLA" },
    { id: nanoid(), symbol: "AAPL" },
    { id: nanoid(), symbol: "AMD" },
    { id: nanoid(), symbol: "NVDA" },
    { id: nanoid(), symbol: "MSFT" },
  ],
});

export const mapToUI = (action: any) => ({
  id: action.id,
  title: action.label,
  stocks:
    action?.params?.items?.map((stock: any) => ({
      id: stock.id,
      symbol: stock.label,
    })) ?? [],
});

export const mapToIQ = (action: any) => ({
  id: action.id,
  type: "stock",
  label: action.title,
  params: {
    id: "symbol",
    label: "Symbol",
    items: action.stocks.map((stock: any) => ({
      id: stock.id,
      label: stock.symbol,
    })),
  },
});

const StockAction: React.FC = () => {
  const { actionId } = useParams<any>();
  const { actions, createAction, updateAction, deleteAction } = useActions();
  const history = useHistory();
  const [openAlert] = useIonAlert();
  const [action, setAction] = useState<any>({});

  const isNew = !actionId;

  const handleSave = () => {
    const { title, stocks } = action;
    if (!title || !stocks.length) {
      return openAlert({
        header: "Error",
        message: "Title and at least one symbol is required",
        buttons: ["OK"],
      });
    }
    onDismiss();
    if (isNew) {
      createAction(mapToIQ(action));
    } else {
      updateAction(mapToIQ(action));
    }
  };

  const handleDelete = () => {
    onDismiss();
    deleteAction({ id: actionId });
  };

  const handleReorderStocks = (event: CustomEvent<ItemReorderEventDetail>) => {
    const { from, to } = event.detail;
    const stocks = action.stocks;
    const copyOfTo = stocks[to];
    stocks[to] = stocks[from];
    stocks[from] = copyOfTo;
    setAction({
      ...action,
      stocks,
    });
    event.detail.complete();
  };

  const handleOpenStock = (stock?: any) => {
    const isAddingStock = !stock;
    openAlert({
      header: isAddingStock ? "Adding stock" : "Editing stock",
      inputs: [
        { name: "symbol", placeholder: "Symbol", value: stock?.symbol, max: 5 },
      ],
      buttons: isAddingStock
        ? [
            {
              text: "Create Stock",
              handler: (input) => {
                const { symbol } = input;
                if (symbol) {
                  setAction({
                    ...action,
                    stocks: [...action.stocks, { id: nanoid(), symbol }],
                  });
                }
              },
            },
          ]
        : [
            {
              text: "Delete",
              cssClass: "!text-red-400",
              handler: () => {
                setAction({
                  ...action,
                  stocks: action.stocks.filter(
                    (element: any) => stock.id !== element.id
                  ),
                });
              },
            },
            {
              text: "Update",
              handler: (input) => {
                const { symbol } = input;
                if (symbol) {
                  setAction({
                    ...action,
                    stocks: action.stocks.map((element: any) => {
                      if (stock.id === element.id) {
                        return {
                          ...element,
                          symbol,
                        };
                      }
                      return element;
                    }),
                  });
                }
              },
            },
          ],
    });
  };

  const onDismiss = () => history.replace("/dashboard");

  useEffect(() => {
    if (isNew) {
      setAction(initialValue());
    } else {
      const current = actions?.find((action) => action.id === actionId);
      if (current) {
        setAction(mapToUI(current));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonModal isOpen onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar style={{ "--background": "transparent" }}>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/dashboard">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="my-2 mx-4 flex flex-col gap-4">
          <IonInput
            counter
            maxlength={32}
            label="Title"
            labelPlacement="floating"
            fill="outline"
            value={action.title}
            onIonInput={(ev) =>
              setAction({ ...action, title: ev.target.value })
            }
          />
          <IonLabel>Available Stocks</IonLabel>
          <IonList>
            <IonReorderGroup
              disabled={false}
              onIonItemReorder={handleReorderStocks}
            >
              {action?.stocks?.map((stock: any) => (
                <IonItem
                  key={stock.id}
                  button
                  onClick={() => handleOpenStock(stock)}
                >
                  <IonLabel>{stock.symbol}</IonLabel>
                  <IonReorder slot="end"></IonReorder>
                </IonItem>
              ))}
            </IonReorderGroup>
            <IonItem button onClick={() => handleOpenStock()}>
              <IonIcon icon={addOutline} slot="end"></IonIcon>
              <IonLabel className="font-bold">Add Symbol</IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <>
            <IonButtons slot="start">
              {!isNew && (
                <IonButton color="danger" onClick={handleDelete}>
                  Delete
                </IonButton>
              )}
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSave}>
                {isNew ? "Create" : "Update"} Action
              </IonButton>
            </IonButtons>
          </>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default StockAction;
