import { User } from "firebase/auth";
import { useEffect, useReducer } from "react";
import { useFirebase } from "../providers/firebase-provider";

enum UseFirebaseAuthEnum {
  get,
  set,
  error,
}

interface IUseFirebaseAuthState {
  loading: boolean;
  user: User;
  error: any;
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case UseFirebaseAuthEnum.get:
      return {
        ...state,
        loading: true,
      };
    case UseFirebaseAuthEnum.set:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case UseFirebaseAuthEnum.error:
      return {
        ...state,
        loading: false,
        user: undefined,
        error: action.payload,
      };
    default:
      return state;
  }
};

const useFirebaseAuth = () => {
  const { auth } = useFirebase();
  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    user: undefined,
    error: null,
  });

  useEffect(() => {
    dispatch({ type: UseFirebaseAuthEnum.get });
    const unsubscribe = auth.onAuthStateChanged(
      (user: User | null) => {
        dispatch({ type: UseFirebaseAuthEnum.set, payload: user });
      },
      (error) => {
        dispatch({ type: UseFirebaseAuthEnum.error, payload: error });
      }
    );
    return () => unsubscribe();
  }, [auth]);

  return state as IUseFirebaseAuthState;
};

export default useFirebaseAuth;
