import React, { useState } from "react";
import {
  IonButton,
  IonPopover,
  IonItem,
  IonList,
  IonIcon,
  IonAlert,
  useIonAlert,
} from "@ionic/react";
import { useHistory } from "react-router";
import { ellipsisVertical } from "ionicons/icons";
import { useFirebase } from "../providers/firebase-provider";
import { updatePassword, updateProfile } from "firebase/auth";

const UserMenu: React.FC = () => {
  const { auth } = useFirebase();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);
  const [showSignOut, setShowSignOut] = useState(false);
  const [openAlert] = useIonAlert();

  const onSignOut = async () => {
    setShowMenu(undefined);
    setTimeout(async () => {
      await auth.signOut();
      history.replace("/login");
    });
  };

  const handleChangeName = async () => {
    setShowMenu(undefined);
    openAlert({
      header: "Change name",
      inputs: [
        {
          name: "name",
          placeholder: "New name",
          value: auth.currentUser?.displayName || "",
          max: 32,
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Change",
          handler: (input) => {
            const { name } = input;
            if (name && auth.currentUser) {
              updateProfile(auth.currentUser, { displayName: name });
            }
          },
        },
      ],
    });
  };

  const handleChangePassword = async () => {
    setShowMenu(undefined);
    openAlert({
      header: "Change password",
      inputs: [
        {
          name: "password",
          placeholder: "New password",
          type: "password",
        },
        {
          name: "passwordConfirmation",
          placeholder: "Confirm new password",
          type: "password",
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Change",
          handler: async (input) => {
            const { password, passwordConfirmation } = input;
            if (password && passwordConfirmation && auth.currentUser) {
              if (password !== passwordConfirmation) {
                // Hack to present the next alert
                return setTimeout(() => {
                  openAlert({
                    header: "Error",
                    message: "Passwords don't match",
                    buttons: ["OK"],
                  });
                }, 500);
              }
              try {
                await updatePassword(auth.currentUser, password);
              } catch (e: any) {
                // Hack to present the next alert
                setTimeout(() => {
                  openAlert({
                    header: "Error",
                    message:
                      e?.message?.replace("Firebase: ", "") ||
                      "Invalid password",
                    buttons: ["OK"],
                  });
                }, 500);
              }
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <IonButton onClick={(e) => setShowMenu(e.nativeEvent)}>
        <IonIcon slot="icon-only" icon={ellipsisVertical} />
      </IonButton>
      <IonPopover
        isOpen={!!showMenu}
        event={showMenu}
        onDidDismiss={(e) => setShowMenu(undefined)}
      >
        <IonList lines="none">
          <IonItem detail={false} button onClick={handleChangeName}>
            Change name
          </IonItem>
          <IonItem detail={false} button onClick={handleChangePassword}>
            Change password
          </IonItem>
          <IonItem
            detail={false}
            button
            onClick={() => {
              setShowMenu(undefined);
              setShowSignOut(true);
            }}
          >
            Logout
          </IonItem>
        </IonList>
      </IonPopover>
      <IonAlert
        isOpen={showSignOut}
        onDidDismiss={() => setShowSignOut(false)}
        header={"Confirmation"}
        message={"Are you sure you want to logout?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => setShowSignOut(false),
          },
          {
            text: "Logout",
            handler: () => onSignOut(),
          },
        ]}
      />
    </>
  );
};

export default UserMenu;
