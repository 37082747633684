import React, { createContext, ReactNode, useContext } from "react";
import { initializeApp, FirebaseApp } from "firebase/app";
import { getAuth, Auth } from "firebase/auth";
import { getFirestore, Firestore } from "firebase/firestore";
import { getFunctions, Functions } from "firebase/functions";
import { getStorage, FirebaseStorage } from "firebase/storage";
import "firebase/analytics";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAHLWhHffauMycegtWiHRWSHr9FmSNeIZQ",
  authDomain: "garminfy.firebaseapp.com",
  projectId: "garminfy",
  storageBucket: "garminfy.appspot.com",
  messagingSenderId: "571505194452",
  appId: "1:571505194452:web:e1bf99488c6a2241c55241",
  measurementId: "G-YJKX98LG8J"
};

interface IFirebase {
  app: FirebaseApp;
  auth: Auth;
  firestore: Firestore;
  functions: Functions;
  storage: FirebaseStorage;
}

const FirebaseContext = createContext<IFirebase>({} as IFirebase);

export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider = ({ children }: { children: ReactNode }) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);
  const value = {
    app,
    auth,
    firestore,
    functions,
    storage,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
