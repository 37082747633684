import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import UserMenu from "../components/UserMenu";
import ActionTypesModal from "../components/ActionTypesModal";
import { useActions } from "../providers/actions-provider";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import TextMessageAction from "../components/actions/TextMessageAction";
import StockAction from "../components/actions/StockAction";
import CryptoAction from "../components/actions/CryptoAction";
import HttpRequestAction from "../components/actions/HttpRequestAction";
import QuoteAction from "../components/actions/QuoteAction";
import WorldTimeAction from "../components/actions/WorldTimeAction";
import CurrencyAction from "../components/actions/CurrencyAction";
import TextMessageGroupAction from "../components/actions/TextMessageGroupAction";
import EmailAction from "../components/actions/EmailAction";

const ACTIONS: any = {
  sms: TextMessageAction,
  smsGroup: TextMessageGroupAction,
  email: EmailAction,
  http: HttpRequestAction,
  stock: StockAction,
  crypto: CryptoAction,
  quote: QuoteAction,
  time: WorldTimeAction,
  currency: CurrencyAction,
};

const ActionsList: React.FC = () => {
  const history = useHistory();
  const { loading, actions, reorderAction } = useActions();
  const handleOpenAction = (action: any) => {
    history.push(`/dashboard/${action.type}/${action.id}`);
  };
  if (loading) {
    return null;
  }
  if (!actions.length) {
    return (
      <div className="pt-16 w-full h-full flex flex-col items-center">
        <img
          className="w-[256px]"
          alt="Empty actions"
          src="./assets/images/empty.png"
        />
        <span className="tracking-wide">It's time to add some actions</span>
      </div>
    );
  }
  return (
    <>
      <div className="ml-4 my-4 text-lg">Actions</div>
      <IonList>
        <IonReorderGroup
          disabled={false}
          onIonItemReorder={(event) => {
            event.detail.complete();
            reorderAction(event.detail.from, event.detail.to);
          }}
        >
          {actions?.map((action: any) => (
            <IonItem
              key={action.id}
              button
              onClick={() => handleOpenAction(action)}
            >
              <IonLabel>{action.label}</IonLabel>
              <IonReorder slot="end"></IonReorder>
            </IonItem>
          ))}
        </IonReorderGroup>
      </IonList>
    </>
  );
};

const Dashboard: React.FC = () => {
  const { loading } = useActions();
  const { path } = useRouteMatch();
  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Dashboard</IonTitle>
            <IonButtons slot="end">
              <UserMenu />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="md:mt-16 mx-auto max-w-[768px]">
            <ActionsList />
          </div>
          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonButton
              shape="round"
              className="normal-case h-12"
              routerLink={`${path}/create`}
            >
              New Action
            </IonButton>
          </IonFab>
        </IonContent>
      </IonPage>
      {!loading && (
        <Switch>
          <Route path={`${path}/create`} component={ActionTypesModal} />
          {Object.keys(ACTIONS).map((action) => (
            <Route
              key={action}
              exact
              path={[`${path}/${action}`, `${path}/${action}/:actionId`]}
              component={ACTIONS[action]}
            />
          ))}
        </Switch>
      )}
    </>
  );
};

export default Dashboard;
