import { IonContent, IonPage } from "@ionic/react";
import { useState } from "react";
import SignInCard from "../components/SignInCard";
import ResetPasswordCard from "../components/ResetPasswordCard";
import SignUpCard from "../components/SignUpCard";
import { useHistory } from "react-router";
import Header from "../components/Header";

const Login: React.FC = () => {
  const history = useHistory();
  const [authForm, setAuthForm] = useState("signin");
  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <div className="flex flex-col items-center justify-center h-screen">
          {authForm === "signin" && (
            <SignInCard
              onSignIn={() => history.replace("/dashboard")}
              onChangeForm={(form) => setAuthForm(form)}
            />
          )}
          {authForm === "reset-password" && (
            <ResetPasswordCard onChangeForm={(form) => setAuthForm(form)} />
          )}
          {authForm === "signup" && (
            <SignUpCard
              onSignUp={() => history.replace("/dashboard")}
              onChangeForm={(form) => setAuthForm(form)}
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
