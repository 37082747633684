import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { nanoid } from "nanoid";
import { closeOutline, addOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useActions } from "../../providers/actions-provider";

export const initialValue = () => ({
  id: nanoid(),
  title: "Http request",
  url: "https://api.garminfy.com/ping",
  method: "GET",
  response: "result",
  params: [],
  headers: [],
});

export const mapToUI = (action: any) => ({
  id: action.id,
  title: action.label,
  url: action.url,
  method: action.method,
  params: Object.keys(action.parameters).map((key) => ({
    id: nanoid(),
    key,
    value: action.parameters[key],
  })),
  headers: Object.keys(action.headers).map((key) => ({
    id: nanoid(),
    key,
    value: action.headers[key],
  })),
  response: action.response,
});

export const mapToIQ = (action: any) => ({
  id: action.id,
  type: "http",
  label: action.title,
  url: action.url,
  method: action.method,
  parameters: action.params.reduce(
    (acc: any, val: any) => ({ ...acc, [val.key]: val.value }),
    {}
  ),
  headers: action.headers.reduce(
    (acc: any, val: any) => ({ ...acc, [val.key]: val.value }),
    {}
  ),
  response: action.response,
});

const HttpRequestAction: React.FC = () => {
  const { actionId } = useParams<any>();
  const { actions, createAction, updateAction, deleteAction } = useActions();
  const history = useHistory();
  const [openAlert] = useIonAlert();
  const [action, setAction] = useState<any>({});

  const isNew = !actionId;

  const handleSave = () => {
    const { title, url } = action;
    if (!title || !url) {
      return openAlert({
        header: "Error",
        message: "Title and URL are required",
        buttons: ["OK"],
      });
    }
    onDismiss();
    if (isNew) {
      createAction(mapToIQ(action));
    } else {
      updateAction(mapToIQ(action));
    }
  };

  const handleDelete = () => {
    onDismiss();
    deleteAction({ id: actionId });
  };

  const handleOpenParam = (param?: any) => {
    const isAddingParam = !param;
    openAlert({
      header: isAddingParam ? "Adding param" : "Editing param",
      inputs: [
        { name: "key", placeholder: "Key", value: param?.key },
        { name: "value", placeholder: "Value", value: param?.value },
      ],
      buttons: isAddingParam
        ? [
            {
              text: "Create Param",
              cssClass: "!text-white-400",
              handler: (input) => {
                const { key, value } = input;
                if (key && value) {
                  setAction({
                    ...action,
                    params: [...action.params, { id: nanoid(), key, value }],
                  });
                }
              },
            },
          ]
        : [
            {
              text: "Delete",
              cssClass: "!text-red-400",
              handler: () => {
                setAction({
                  ...action,
                  params: action.params.filter(
                    (element: any) => element.id !== param.id
                  ),
                });
              },
            },
            {
              text: "Update",
              handler: (input) => {
                const { key, value } = input;
                if (key && value) {
                  setAction({
                    ...action,
                    params: action.params.map((element: any) => {
                      if (element.id === param.id) {
                        return {
                          ...element,
                          key,
                          value,
                        };
                      }
                      return element;
                    }),
                  });
                }
              },
            },
          ],
    });
  };

  const handleOpenHeader = (header?: any) => {
    const isAddingHeader = !header;
    openAlert({
      header: isAddingHeader ? "Adding header" : "Editing header",
      inputs: [
        { name: "key", placeholder: "Key", value: header?.key },
        { name: "value", placeholder: "Value", value: header?.value },
      ],
      buttons: isAddingHeader
        ? [
            {
              text: "Create Header",
              cssClass: "!text-white-400",
              handler: (input) => {
                const { key, value } = input;
                if (key && value) {
                  setAction({
                    ...action,
                    headers: [...action.headers, { id: nanoid(), key, value }],
                  });
                }
              },
            },
          ]
        : [
            {
              text: "Delete",
              cssClass: "!text-red-400",
              handler: () => {
                setAction({
                  ...action,
                  headers: action.headers.filter(
                    (element: any) => element.id !== header.id
                  ),
                });
              },
            },
            {
              text: "Update",
              handler: (input) => {
                const { key, value } = input;
                if (key && value) {
                  setAction({
                    ...action,
                    headers: action.headers.map((element: any) => {
                      if (element.id === header.id) {
                        return {
                          ...element,
                          key,
                          value,
                        };
                      }
                      return element;
                    }),
                  });
                }
              },
            },
          ],
    });
  };

  const onDismiss = () => history.replace("/dashboard");

  useEffect(() => {
    if (isNew) {
      setAction(initialValue());
    } else {
      const current = actions?.find((action) => action.id === actionId);
      if (current) {
        setAction(mapToUI(current));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonModal isOpen onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar style={{ "--background": "transparent" }}>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/dashboard">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="my-2 mx-4 flex flex-col gap-4">
          <IonInput
            counter
            maxlength={32}
            label="Title"
            labelPlacement="floating"
            fill="outline"
            value={action.title}
            onIonInput={(ev) =>
              setAction({ ...action, title: ev.target.value })
            }
          />
          <IonInput
            label="URL"
            labelPlacement="stacked"
            fill="outline"
            value={action.url}
            onIonInput={(ev) => setAction({ ...action, url: ev.target.value })}
          />
          <IonItem>
            <IonSelect
              label="Method"
              placeholder="Http Method"
              value={action.method}
              onIonChange={(ev) =>
                setAction({ ...action, method: ev.target.value })
              }
            >
              <IonSelectOption value="GET">GET</IonSelectOption>
              <IonSelectOption value="POST">POST</IonSelectOption>
              <IonSelectOption value="PUT">PUT</IonSelectOption>
              <IonSelectOption value="DELETE">DELETE</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonLabel>Params</IonLabel>
          <IonList>
            {action?.params?.map((param: any) => (
              <IonItem
                key={param.id}
                button
                onClick={() => handleOpenParam(param)}
              >
                <IonLabel>
                  {param.key}
                  {": "}
                  {param.value}
                </IonLabel>
              </IonItem>
            ))}
            <IonItem button onClick={() => handleOpenParam()}>
              <IonIcon icon={addOutline} slot="end"></IonIcon>
              <IonLabel className="font-bold">Add Param</IonLabel>
            </IonItem>
          </IonList>
          <IonLabel>Headers</IonLabel>
          <IonList>
            {action?.headers?.map((header: any) => (
              <IonItem
                key={header.id}
                button
                onClick={() => handleOpenHeader(header)}
              >
                <IonLabel>
                  {header.key}
                  {": "}
                  {header.value}
                </IonLabel>
              </IonItem>
            ))}
            <IonItem button onClick={() => handleOpenHeader()}>
              <IonIcon icon={addOutline} slot="end"></IonIcon>
              <IonLabel className="font-bold">Add Header</IonLabel>
            </IonItem>
          </IonList>
          <IonInput
            label="Response field"
            labelPlacement="floating"
            fill="outline"
            value={action.response}
            helperText="Used on JSON, leave blank if no response is needed"
            onIonInput={(ev) =>
              setAction({ ...action, response: ev.target.value })
            }
          />
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <>
            <IonButtons slot="start">
              {!isNew && (
                <IonButton color="danger" onClick={handleDelete}>
                  Delete
                </IonButton>
              )}
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSave}>
                {isNew ? "Create" : "Update"} Action
              </IonButton>
            </IonButtons>
          </>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default HttpRequestAction;
